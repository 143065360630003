import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
const items = [
  {
    src:
      "url(" +
      require("../../assets/img/webp/back_2.webp") +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="10">
            <h1 className="title">{/*Votre protection nous tiens à coeur*/} Votre protection = Notre mission </h1>
            <Col className="text-left" md="6">
            <h5 md="6">
              Spécialisés et passionés par la cybersécurité et l'innovation digitale et technologique
            </h5>
            </Col>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                href="/about-us"
                size="lg"
              >
                En savoir plus
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src:
      "url(" +
      require("assets/img/webp/back_1.webp") +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">Êtes-vous protégés contre les cyberattaques ?</h1>
            <h5>
              Nous aidons les entreprises à sécuriser leurs actifs et de prévenir les cyberattaques.
              Nos différents services sont personalisés et adaptés à vos besoins. 
              
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                href="/services"
                size="lg"
              >
                En savoir plus
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src:
      "url(" +
      require("assets/img/webp/back_3_1024.webp") +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">Cybersécurité</h1>
            <h5>
             Sécuriser vos infrastructures et applications est notre plus grande priorité
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                href="/services"
                size="lg"
              >
                En savoir plus
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  }
];

function LandingPageHeader() {
  // carousel - header 3
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
  return (
    <>
      <div className="section-header cd-section" id="headers">

        {/* ********* HEADER ********* */}
        <div className="header-3">
          <div className="page-carousel" >
            <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
        {/* ********* END HEADER ********* */}
      </div>
    </>
  );
}

export default LandingPageHeader;
