import React from "react";
import { MdLock  } from "@react-icons/all-files/md/MdLock";
import { GoBug } from "@react-icons/all-files/go/GoBug";


// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionServices() {
  return (
    <>
      <div className="section section-feature cd-section" id="features">
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Nos services</h2>
                <h5 className="description">
                  Nous accompagnons les entreprises à améliorer la sécurité de leurs 
                  systèmes d'information et à répondre aux différentes normes de conformité. 
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="" ><GoBug /></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Tests d'intrusion</h4>
                    <p className="description">
                      Simulation d'une cyberattaque contre votre organisation afin d'identifier les vulnérabilités
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/services#pentest"
                    >
                      Détails
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon" > <MdLock /></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Audit & conseils</h4>
                    <p>
                      Sécuriser vos systèmes selon les normes de conformité les plus reconnues
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/services#audit"
                    >
                      Détails
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Solutions sur-mesure</h4>
                    <p>
                      Définir, réaliser et déployer différentes solutions de sécurité et de monitoring
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/services#solutions"
                    >
                      Détails
                    </Button>
                  </div>
                </div>
              </Col>              
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionServices;
