import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";


import { Link } from 'react-router-dom'

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import Highlight from 'react-highlight';

function BlogPost() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <ColorNavbar />
      <BlogPostHeader />
     
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="8">
                  <h1>Sécuriser PostgreSQL - Linux</h1>
                  <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                    <Badge className="main-tag" color="info">
                      Technique
                    </Badge>
                    <h6 className="title-uppercase">14 juillet 2021</h6>
                  </div>
                </Col>
                </Col>
              </Row>
              <Row>
                
                <Col className="ml-auto mr-auto" md="8">
                
                  <div className="article-content" style={{ fontFamily:"Roboto, sans-serif", fontSize: "16px", color: "#7f7f7f" }}>
                    
                      PostgreSQL est le deuxième système de gestion de base de données le plus utilisé par les professionels
                      selon le sondage de <a href="https://insights.stackoverflow.com/survey/2020#technology-databases-professional-developers4">
                      Stack Overflow Developer Survey 2020
                      </a>. Nous vous proposons quelques points clés pour sécuriser 
                      vos bases de données PostgreSQL.
                    
                    <br />
                    <br />
                      Avant de mettre le serveur PostgreSQL en production, il est primordial 
                      de s'assurer qu'un minimum de sécurité soit mis en place.  
                      
                      <br /><br />
                      Dans cet article nous abordons quelques meilleures pratiques: <br />
                      <ul>
                        <li>Sauvegardes WAL</li>
                        <li>Authentification et accès</li>
                        <li>Journalisation</li>
                        <li>Réplication</li>
                      </ul>
                  
                      Le fichier 'postgresql.conf' contient les différents paramètres
                      de configuration du serveur Postgres. Quelques paramètres peuvent 
                      être modifiés afin de renforcer la sécurité.
                   
                    <h3>
                      <strong>Adresse d'écoute</strong>
                    </h3>
                    <br />
                  
                    Vous pouvez utiliser le paramètre 'listen_address' pour contrôler l'adresse IP sur laquelle 
                    le serveur va écouter, cela permet de réduire le champ des IPs qui se connecteront au serveur. <br /><br />
                    C'est une bonne pratique d'écouter les connexions uniquement sur le réseau souhaité 
                    et d'éviter les valeurs générales comme "*", "0.0.0.0" ou "::", qui indiqueront 
                    à Postgres d'écouter sur toutes les interfaces réseaux disponibles et accepter 
                    les connexions de n'importe quelle IP.<br /><br />
                    Si le serveur PostgreSQL n'est pas accessible depuis un autre service distant, il est recommandé
                    de mettre la valeur du paramètre 'listen_address' à '127.0.0.1', 'localhost' ou simplement ' '.
                    Si le champ 'listen_address' est vide (''), la communication Postgres s'effectuera uniquement 
                    à l'aide des sockets Unix.<br />
                   
                   <h3>
                      <strong>Sauvegarde WAL (Write Ahead Log)</strong>
                   </h3>
                    <br />
                   
                    Il est important de garder une sauvegarde WAL, idéalement 
                    dans un serveur distant, destinée à sauvegarder les transactions. <br /><br />
                    Cette fonctionalité peut être activée à l'aide des deux paramètres 
                     <strong> 'archive_mode'</strong> et <strong>'archive_command'</strong>: <br />
                     <ul>
                       <li><strong>'archive_mode'</strong> doit avoir la valeur <b>'on'</b></li>
                       <li><strong>'archive_command'</strong> correspond à la commande qui aura pour but d'effectuer
                      la sauvegarde. Voici quelques exemples de commandes:</li>
                      <ul>
                       <li> Copie en local dans le répertoire '/opt/pg_wal/backup/': 
                       <Highlight >
                        {
                          "\n"+
                          "archive_command = 'cp %p /opt/pg_wal/backup/%f'"+
                          "\n"
                        }
                        </Highlight></li>
                       <li> Copie dans un serveur distant en utilisant 'rsync': <br />
                       <Highlight >
                        {
                          "\n"+
                          "archive_command = 'rsync -e ssh -a %p postgres_user@backup_host:/var/lib/pgsql/WAL_backup/%f'"+
                          "\n"
                        }
                        </Highlight>
                        </li>
                        </ul>
                    </ul>
                   
                    <h3>
                      <strong>Authentification et accès</strong>
                    </h3>
                    <br />
                    <h5>
                      <strong>Utilisateurs </strong>
                    </h5>
                    
                    La règle d'or de la sécurité en matière de gestion des utilisateurs est d'accorder aux utilisateurs le minimum d'accès dont ils ont besoin.<br /><br />

                    Cette gestion peut devenir très compliquée si elle n'est pas effectuée correctement dès le départ.<br /><br />

                    Un bon moyen de garder les privilèges sous contrôle est d'utiliser la stratégie rôle, groupe, utilisateur.<br /><br />

                    Dans Postgres, tout est considéré comme un rôle.
                    Dans cette stratégie, trois types de rôles différents seront créés:                    
                        <ul>
                          <li>rôle (identifié par le préfixe r_)</li>
                          <li>groupe (identifié par le préfixe g_)</li>
                          <li>utilisateur (généralement des noms d'utilisateurs ou d'applications)</li>
                          </ul>
                    Les rôles (rôles r_) seront ceux qui auront les privilèges sur les objets. 
                    Les rôles de groupe (rôles g_) seront accordés avec les rôles r_, ils 
                    seront donc une collection de rôles r_. Et enfin, les rôles d'utilisateurs 
                    et d'applications seront accordés avec un ou plusieurs rôles de groupe et 
                    seront ceux qui auront le privilège de connexion.
                    <br />
                    <br />
                    Ajouter à chaque rôle, groupe ou utilisateur une abréviation des droits 
                    accordés à la fin du nom. Par exemple, pour un rôle qui aura les 
                    droits de lecture seule, le nom 'r_role_name_ro' lui sera attribué 
                    ('ro' à la fin du nom signifiant "read only") et sa création s'effectuera comme suit:<br />
                    <Highlight >
                      {"\n"+
                      "CREATE ROLE r_role_name_ro NOSUPERUSER NOREPLICATION NOCREATEDB NOCREATEROLE INHERIT;"
                    }
                    </Highlight>
            
                    <br />
                    <h5> 
                      <strong>Authentification</strong>
                    </h5>
                    
                    L'authentification est un élément crucial 
                    pour garantir que l'accès aux ressources 
                    soit uniquement accordé aux utilisateurs autorisés. <br /> <br />

                    PostgreSQL propose <a href="https://www.postgresql.org/docs/current/auth-methods.html"> différents types d'authentifications</a>. 
                    Parmi celles-ci, on trouve 'Trust', 'Password', 'Certificate authentication', 'PAM' ou 'LDAP'. <br /><br />
                    La première méthode, 'Trust', est déconseillée car elle considère 
                    que chaque personne qui a accès au service Postgres est autorisée 
                    à accéder aux bases de données. <br /><br />
                    
                    La méthode 'Password' est une authentification 
                    basée sur un mot de passe. Pour cette méthode 
                    d'authentification, il est recommandé d'utiliser 
                    'scram-sha-256' qui est considéré comme la méthode 
                    la plus sécurisée pour l'authentification par mot 
                    de passe. Pour ce faire, il suffit de mettre la 
                    valeur du paramètre 'password_encryption', dans 
                    le fichier de configuration 'postgresql.conf', 
                    à 'scram-sha-256':<br />
                    <Highlight >
                      {"\n"+
                        "password_encryption = scram-sha-256"
                      }
                    </Highlight>
                  

                    Le maintien d'une politique de mots de passe forts est indispensable pour assurer la sécurité de vos bases de données. Utilisez de préférence des caractères spéciaux, des chiffres, des majuscules et des minuscules et ayez au moins 10 caractères.<br />
                    <br />

                    Les outils d'authentification externes, comme LDAP ou PAM,  peuvent vous aider à mettre en place votre politique d'expiration et de réutilisation des mots de passe ainsi que d'assurer la gestion du verrouillage des comptes en cas d'erreur d'authentification.<br /><br />


                    La méthode 'Certificate authentication' utilise les certificats 
                    SSL afin d'authentifier les utilsateurs, ce qui implique
                     qu'elle sera uniquement disponible pour les connexions SSL. <br />
                    Cette méthode permet d'avoir un meilleur contôle d'accès aux 
                    bases de données mais représente plus de temps de gestion car 
                    pour chaque utilisateur/application un certificat SSL doit être créé. <br />
                    <br/>
                    <h5> 
                      <strong>Nombre de connexions simultanées</strong>
                    </h5>
                  
                    Postgres offre la possibilité de limiter le nombre de connexions simultanées
                    au service. Définir cette limite est vivement recommandé car elle permet de 
                    se protéger contre les attaques de type dénis de service (DoS et DDoS).
                      
                    <h3>
                      <strong>Algorithmes de chiffrement</strong>
                    </h3>
                    <br />
                    Dans le cas où SSL est activé pour l'authentification et la 
                    communication avec le serveur Postgres, il est recommandé de 
                    limiter les algorithmes de chiffrement qui sont utilisés.<br />
                    Cela peut être configuré en modifiant le paramètre 'ssl_ciphers'
                    dans le fichier 'postgresql.conf':
                    <br />
                    <Highlight >
                      {
                        "\n"+
                        "ssl_ciphers='DHE-RSA-AES256-GCM-SHA384:DHE-RSA-AES128-GCM-SHA256:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-RSA-AES128-GCM-SHA256:DHE-RSA-AES256-SHA256:DHE-RSA-AES128-SHA256:ECDHE-RSA-AES256-SHA384:ECDHE-RSA-AES128-SHA256::!aNULL:!eNULL:!LOW:!3DES:!MD5:!EXP:!PSK:!DSS:!RC4:!SEED:!ECDSA:!ADH:!IDEA:!3DES'\n"+
                        "\n"
                      }
                    </Highlight>
                    
                    <h3>
                      <strong>Journalisation</strong>
                    </h3>
                    <br />
                      Postgres fournit une grande variété de paramètres de configuration pour contrôler la journalisation.

                      Vous pouvez surveiller toutes les actions relatives aux sessions (connexion/déconnexion), 
                      les requêtes de longue durée, la taille des fichiers temporaires, etc. 
                      Cela peut aider à avoir une meilleure compréhension lors de vos développements
                      mais aussi d'identifier les comportements ou les tentatives malveillantes.<br />
                      Vous trouverez plus d'informations sur les options de journalisation sur le lien suivant: 
                      <a href="https://www.postgresql.org/docs/current/runtime-config-logging.html"> https://www.postgresql.org/docs/current/runtime-config-logging.html
                      </a>
                      
                      <h3>
                      <strong>Row Security Policies</strong>
                    </h3>
                    <br />
                    Les politiques de sécurité des lignes (Row Security Policies),
                    aussi connues sous le nom de "Row-Level Security" ou "RLS", 
                    permettent de définir des politiques qui empêchent les utilisateurs 
                    de visualiser ou de manipuler des enregistrements spécifiques de données 
                    dans une table. Ces politiques peuvent être utilisées en plus des 
                    privilèges standards SQL disponibles via GRANT.<br />
                    Cela peut être particulièrement utile pour bloquer l'accès à des 
                    enregistrements sensibles, comme les données clients ou financières.

                     <h3>
                      <strong>Réplication</strong>
                    </h3>
                    <br />
                    Postgres offre deux types de réplications: 'Streaming' 
                    et 'Logical'. La réplication intégrée n'est disponible 
                    que dans la configuration primary-standby (une base 
                    de données primaire: "master" et une base de données 
                    de sauvegarde: "standby").<br /><br />

                    La réplication en continu (Streaming replication), 
                    également connue sous le nom de réplication 
                    physique et binaire dans Postgres, consiste à transmettre 
                    les données en continu aux serveurs de
                    sauvegarde (standby) byte par byte. Afin de 
                    diffuser ces données, la réplication en continu 
                    utilise les enregistrements WAL. Tout changement 
                    effectué sur le serveur primaire est d'abord 
                    écrit dans les fichiers WAL avant d'être écrit 
                    sur le disque et le serveur de base de données qui a 
                    la capacité de diffuser ces enregistrements vers 
                    un ou plusieurs serveurs standby.<br /><br />

                    La réplication en continu est asynchrone par 
                    défaut, mais il est possible d'activer 
                    la réplication synchrone. Ce type de configuration 
                    est idéal pour obtenir un environnement de haute 
                    disponibilité (High Availability). Si le serveur 
                    principal tombe en panne, l'un des serveurs standby 
                    peut prendre sa place puisque c'est une copie 
                    presque identique.<br />
                    <br />

                    La réplication logique (Logical replication) est plus récente
                    que la précedente et suit un model "publish subscribe". Celle-ci 
                    résout les limitations de la réplication en continu et permet de:
                    <ul>
                      <li>Envoyer des changements incrémentiels dans une base de 
                        données unique ou un sous-ensemble d'une base de données 
                        aux abonnés (subscribers) au fur et à mesure qu'ils se 
                        produisent</li>

                      <li>Déclencher les 'trigger' pour des changements individuels 
                        lorsqu'ils arrivent sur l'abonné</li>

                      <li>Consolider plusieurs bases de données en une seule</li>

                      <li>Répliquer entre différentes versions de Postgres</li>

                      <li>Répliquer entre des instances Postgres sur différentes plateformes (par exemple de Linux à Windows ou l'inverse)</li>

                      <li>Donner accès aux données répliquées à différents groupes d'utilisateurs</li>

                      <li>Partager un sous-ensemble de la base de données entre plusieurs bases de données</li>

                    </ul>
                    <br />
                    Il est fortement recommandé d'activer la réplication uniquement en SSL 
                    car le trafic entre le serveur primaire et le serveur standby n'est 
                    pas chiffré par défaut. 
                    Cela pourrait avoir un impact minime sur les performances mais on peut 
                    le considérer comme négligeable. 
                    Pour ce faire, le fichier 'pg_hba.conf' contient toutes les règles 
                    nécessaires pour l'authentification des utilisateurs, par exemple:
                    <Highlight >
                      {"\n"+
                        "# TYPE  DATABASE        USER            ADDRESS                 METHOD\n"+
                        "\n"+
                        "hostssl    MY_DB     replicauser_ro     169.168.1.2/32        cert clientcert=1"+
                        "\n"
                      }
                    </Highlight>                    

                    <h4>
                      <strong>Conclusion</strong>
                    </h4>
                    <br />
                    Si vous suivez les conseils ci-dessus, votre serveur sera plus sûr, 
                    mais cela ne signifie pas qu'il sera inviolable.<br />
                    
                    En attendant le prochain article qui concernera 
                    le monitoring et le hardening d'un système Linux qui héberge un
                    service PostgreSQL, n'hésitez pas à nous contacter pour des éventuelles 
                    questions ou commentaires. 
                    <br /><br />
                    <div className="content-center text-center">
                      <Link to="/about-us#contact-us">
                        <Button
                            className="btn"
                            color="danger"
                          >
                          Nous contacter
                        </Button>
                      </Link>
                    </div>
                    

                  
                  </div>
                  <br />
                 <hr />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default BlogPost;
