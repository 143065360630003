import React from "react";
import Slider from "nouislider";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

//import { Link } from 'react-router-dom'

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionContactUs from "views/sections-sections/SectionContactUs.js";
import SectionProject from "./sections-sections/SectionProject";


function ServicesDetails() {
  document.documentElement.classList.remove("nav-open");
  const [pentestService, setPentestService] = React.useState(true);
  const [auditService, setAuditService] = React.useState(false);
  const [complianceService, setComplianceService] = React.useState(false);
  const [solutionService, setSolutionService] = React.useState(false);
  document.documentElement.classList.remove("nav-open");

  const linkStyle = {
    textDecoration: "none",
    color: 'blue'
  };

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };

  });
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="section-space" />
      <div className="section section-gray">
        <Container>
        <Row >
          <Col md ="3">

          </Col>
            <Col md="9">
              <CardBody style={{ fontSize: "16px", color: "#7f7f7f" }}>
                <h3>Découvrez comment pouvons-nous sécuriser vos actifs</h3><br />

                Parlons de la façon dont ICTrust peut répondre à vos besoins en matière de cybersécurité.  
                <br />
                Appelez-nous, envoyez-nous un e-mail, ou remplissez le formulaire de contact ci-dessous pour commencer.
              </CardBody>
                     
            </Col>
            <Col md="3">
              <h3 className="section-title">Services</h3>
              <Card className="card-refine">
                <div
                  aria-expanded={true}
                  aria-multiselectable={true}
                  className="panel-group"
                  id="accordion"
                >
                  <CardHeader
                    className="card-collapse"
                    id="pentest"
                    role="tab"
                  >

                    <h5 className="mb-0 panel-title" {...(pentestService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a

                        aria-expanded={pentestService}
                        href="#desc"
                        onClick={(e) => {
                          setPentestService(!pentestService);
                          setAuditService(false);
                          setComplianceService(false);
                          setSolutionService(false);
                          
                        }}
                      >
                        Test d'intrusion <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>

                  </CardHeader>

                  <CardHeader
                    className="card-collapse"
                    id="auditService"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(auditService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={auditService}
                        href="#desc"
                        onClick={(e) => {
                          setAuditService(!auditService);
                          setPentestService(auditService ? true : false);
                          setComplianceService(false);
                          setSolutionService(false);
                        }}
                      >
                        Audit de sécurité <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="compliance"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(complianceService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={complianceService}
                        href="#desc"
                        onClick={(e) => {
                          setComplianceService(!complianceService);
                          setPentestService(complianceService ? true : false);
                          setAuditService(false);
                          setSolutionService(false);
                          window.location.hash = "desc";
                        }}

                      >
                        Normes de conformité <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="solutions"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(solutionService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={solutionService}
                        href="#desc"
                        onClick={(e) => {
                          setSolutionService(!solutionService)
                          setComplianceService(false);
                          setPentestService(solutionService ? true : false);
                          setAuditService(false);
                          window.location.hash = "desc";
                        }}

                      >
                        Solutions sur-mesure <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>

                </div>
              </Card>
              {/* end card */}
            </Col>
            
            <Col md="9">
              <div className="products" id="desc">
                <Collapse isOpen={pentestService}>
                  <CardBody style={{ fontSize: "16px", color: "#7f7f7f" }}>
                    <div class="row" id="pentest-desc">
                      <div class="col-md-12" >
                        <div class="article-content mb-40">
                          <h2>Test d'intrusion (Pentest)</h2>
                          <br />
                            Un test d'intrusion, test de pénétration ou pentest est une méthode qui consiste à se mettre dans la peau d'un attaquant afin d'analyser le niveau de sécurité de votre organisation.

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="article-content mb-40">
                          <h3>Buts</h3>
                          <ul class="check-list">
                            <li>Vérifier et identifier les types d'attaques ou de vulnérabilités d'un système d'information (Infrastructure, système, application...)</li>
                            <br />
                            <li>Identifier les failles dans les systèmes d'information et  les failles humaines avant les attaquants malveillants</li>
                            <br />
                            <li>Prévenir la perte de données, la disponibilité et l'intégrité au sein des systèmes d'information de votre organisation</li>
                            <br />
                            <li>Fournir des recommandations pour améliorer le niveau de sécurité des systèmes d'information</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="article-content mb-30">
                      <h3><span>Notre Approche</span></h3>
                            Notre méthodologie s'appuie sur les différents standards mis en place par la communauté internationale de la cybersécurité, dont notamment l'OWASP.<br /><br />
                            Les tests d'intrusions s'appuient sur un processus cyclique en trois phases:
                      <ol class="gradient-list">
                        <li>
                          <strong>Phase de planification </strong><br />
                          Définir avec vous les champs d'attaques, le type de test (Black box, Grey box ou White box) ainsi que les informations requises pour l'environnement à tester.<br /><br />
                        </li>
                        <li>
                          <strong>Phase d'exécution/attaques</strong><br />
                          Rechercher les failles manuellement ainsi qu'avec des outils automatiques (outils propriétaires, outils open-source et outils développés en interne). La combinaison de ces deux approches permet de profiter de la puissance de l'automatisation pour augmenter l'efficacité et l'efficience du pentest. L'analyse humaine permet de découvrir des vulnérabilités non détectables par des outils (par exemple les failles logiques) et d'évaluer l'impact des vulnérabilités détectées.<br /><br />
                        </li>
                        <li>
                          <strong>Phase de post-exécution</strong><br />
                          Livrer un rapport exhaustif détaillant les vulnérabilités identifiées, leur exploitation possible ainsi que les corrections nécessaires à implémenter. Il peut aussi être complété par une phase de validation qui vous permettra de vous assurer que toutes les vulnérabilités identifiées ont été corrigées.
                        </li>
                      </ol>
                    </div>
                  </CardBody>
                </Collapse>
                <Collapse isOpen={auditService} style={{ fontSize: "16px", color: "#7f7f7f" }}>
                  <CardBody style={{ fontSize: "16px", color: "#7f7f7f" }}>
                    <div class="row" id="audit-desc">
                      <div class="col-md-12">
                        <div class="article-content mb-40">

                          <h2>Audit de sécurité</h2>
                          <br />
                          Un audit de sécurité informatique est essentiellement une évaluation globale 
                          des pratiques de sécurité informatique de l’organisation, tant physiques que non physiques, 
                          qui peuvent potentiellement conduire à sa compromission. 
                          C'est une étape vitale pour se protéger contre les cyberattaques, les fraudes et les fuites d'informations.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="article-content mb-40">
                          <h3>But</h3>
                          <ul class="check-list">
                            <li>Protéger les ressources de données critiques d'une organisation.</li><br />
                            <li>Identifier les failles de sécurité avant les attaquants.</li><br />
                            <li>Tenir l'organisation informée des mesures de sécurité.</li><br />
                            <li>Aider à la formulation de nouvelles politiques de sécurité pour l'organisation.</li><br/>
                            <li>Préparer l'organisation à une intervention d’urgence en cas de violation de la cybersécurité.</li><br/>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="article-content mb-30">
                      <h3><span>Notre Approche</span></h3><br />
                      <ol class="gradient-list article-content">
                        <li> Analyser <b> les systèmes d'information, les applications et les 
                          produits les plus importants de votre organisation</b> pour receuillir les 
                          informations nécessaires pour effectuer l'audit. </li>
                          <br />
                        <li> Évaluer les risques et établir d'un plan d'audit.</li>
                        <br />
                        <li> Analyser les informations receuillies afin d'avoir une vue d'ensemble complète 
                          de tous les aspects de sécurité liés à la sécurité des systèmes d'information au 
                          différents niveaux définis au cours de la premières phase.</li>
                          <br />
                        <li> Présenter les résultats sous forme d'un rapport détaillé et complet. Ce rapport 
                          contient des conclusions utiles, sur l'objet et l'aspect, ainsi que des recommendations
                           pour remédier aux différentes failles constatées sous la forme d'un plan d'action.</li>
                      </ol>
                    </div>

                  </CardBody>
                </Collapse>

                <Collapse isOpen={complianceService}>
                  <CardBody style={{ fontSize: "16px", color: "#7f7f7f" }}>
                    <div class="row" id="compliance-desc">
                      <div class="col-md-12">
                        <div class="article-content mb-40">
                          <h2>Normes de conformité</h2><br />
                          Le respect des normes de conformité de sécurité peut être défini comme le respect des procédures ou des réglementations ainsi que l'interdiction des actions qui nuisent aux différents systèmes d'information de votre organisation. Ceci améliore les capacités de la gestion de vos systèmes d'information et permet d'avoir un processus défini qui renforcera leur sécurité.
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-10 col-lg-16">
                        <div class="article-content mb-40">
                          <h3>Différentes normes de conformité</h3>
                          <br />
                          <ul>
                            <li>

                              <strong>RGPD (Règlement Général sur la Protection des Données)</strong><br />
                                Ce règlement unifie la protection des données à caractères personnels et s'applique à toutes les entreprises traitant des données qui pourront identifier directement ou indirectement une personne physique dans l'Union Européenne.

                            </li>
                            <br /><br />
                            <li>

                              <strong>CIS (Center for Internet Security)</strong><br />
                                CIS est une entité à but non lucratif dont la mission est :<br/><i >"identifier, développer, valider, promouvoir et soutenir les solutions des meilleures pratiques recommandées pour la cyberdéfense".</i><br />
                                 Les différentes recommandations de l'organisme permettent de renforcer la sécurité de l'environnement de travail des entreprises.

                            </li>
                            <br /><br />
                            <li>

                              <strong>NIST 800-171</strong><br />
                                Il s'agit d'une publication spéciale du "<a style={{color: "black", strong: "true"}} href="https://www.nist.gov/"><i>National Institute of Standards and Technology</i></a>" qui vise à protéger la confidentialité des "informations non classifiées contrôlées" (Controlled Unclassified Information). Cette norme a pour but d'adhérer à un niveau de sécurité raisonnable en définissant des exigences en quatre catégories principales:
                              <ul class="check-list">
                                <li>Contrôles et processus de gestion et de protection</li>
                                <li>Surveillance et gestion des systèmes informatiques</li>
                                <li>Procédures et pratiques claires pour les utilisateurs finaux</li>
                                <li>Implémentation de mesures de sécurité physiques et technologiques</li>
                              
                              </ul>


                            </li>
                          </ul>
                        </div>

                      </div>

                    </div>
                  </CardBody>
                </Collapse>
                <Collapse isOpen={solutionService}>
                  <CardBody style={{ fontSize: "16px", color: "#7f7f7f" }}>
                    <div class="row"  id="solutions-desc">
                      <div class="col-md-12">
                        <div class="article-content mb-40">
                          <h2>Conception de solutions</h2><br />
                          
                    
                            Nous définissons et déployons des solutions sur-mesure, selon vos besoins et votre budget, dans le but de sécuriser et surveiller vos infrastructures en utilisant des solutions open source ou propriétaires. <br /> <br />
                            Nous vous proposons également des services d'accompagnement lors de vos conceptions techniques orientées sécurité sur les procédures, les technologies ou le développement et la supervision de leur mise en oeuvre.
                        
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-10 col-lg-16">
                        <div class="article-content mb-40">
                          <h3>Solutions</h3>
                          <ol>
                            <li>
                              <strong>WAF (Web Application Firewall)</strong><br />
                                  Un pare-feu pour les applications web est une solution qui contrôle le trafic redirigé vers vos applications web de manière transparente. Le but d'un WAF est de les protéger en détectant et en bloquant le trafic qui semble suspicieux selon des règles prédéfinies.
                            </li>
                            <br />
                            <li>
                              <strong>IDS (Intrusion Detection System)</strong><br />     
                                  Un système de détection d'intrusion permet de surveiller le trafic réseau, alerter et bloquer celui qui semble suspicieux.
                            </li><br />
                            <li>
                              <strong>SIEM (Security Information and Event Management) </strong><br />    
                                  Un système de gestion de l'information et des événements de sécurité qui permet de collecter, normaliser et corréler  les journeaux systèmes des différents équipements de votre infrastructure.             
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div class="article-content mb-30">
                      <h3>Autres</h3>
                      Pour d'autres solutions, n'hésitez pas à nous contacter et nous nous ferons un plaisir à vous répondre et vous trouver la solution idéale adaptée à vos besoins.
                    </div>
                  </CardBody>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Container>
        
      </div>
      <SectionContactUs />
      <FooterBlack />
    </>
  );
}

export default ServicesDetails;
