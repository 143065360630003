import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import FooterBlack from "components/Footers/FooterBlack.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";

function BlogPosts() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <ColorNavbar />
      <BlogPostHeader />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              {/* ********* BLOGS 3 ********* */}
              <div className="blog-3">
                <Container>
                  <Row>
                    <Col className="ml-auto mr-auto" md="10">
                      <h2 className="title">Articles récents</h2>
                      <br />
                      <Card className="card-plain card-blog">
                      
                      <Row>
                          
                          <Col md="8">
                          <CardBody>
                              <h6 className="card-category text-danger">Vulnérabilité</h6>
                              <CardTitle tag="h3">
                                <a href="/blog/post/Log4j">
                                  RCE: La vulnérabilité Log4j activement exploitée
                                </a>
                              </CardTitle>
                              
                              Les versions Log4j inférieures à la version 2.15.0 sont vulnérables à une execution de code à distance (RCE: Remote Code Execution).
                              Cette vulnérabilité est critique et activement exploitée. {" "}
                                <a href="/blog/post/Log4j">
                                  Lire la suite
                                </a>
                                <br /> <br />
                            </CardBody>
                          </Col>
                          <Col md="4">
                            <div className="card-image">
                              <img
                                alt="Log4j"
                                className="img"
                                src={
                                  require("assets/img/Apache_Log4j_Logo.png")
                                    
                                }
                                style={{ maxWidth: "120%", marginTop: "3em"}}
                              />
                            </div>
                           
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col md="4">
                            <div className="card-image">
                              <img
                                alt="Hardening PostgreSQL"
                                className="img"
                                src={
                                  require("assets/img/Postgresql_elephant.png")
                                    
                                }
                              />
                            </div>
                          </Col>
                          <Col md="8">
                            <CardBody>
                              <h6 className="card-category text-info">Technique</h6>
                              <CardTitle tag="h3">
                                <a href="/blog/post/postgresql_hardening">
                                  Renforcer la sécurité de vos bases de données PostgreSQL
                                </a>
                              </CardTitle>
                              
                                PostgreSQL est le deuxième SGBD le plus utilisé par les professionels
                                selon le sondage de <a href="https://insights.stackoverflow.com/survey/2020#technology-databases-professional-developers4">
                                 Stack Overflow Developer Survey 2020
                                </a>. Nous vous proposons quelques points clés pour sécuriser vos bases de données PostgreSQL.{" "}
                                <a href="/blog/post/postgresql_hardening">
                                  Lire la suite
                                </a>
                              
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                      
                      <Card className="card-plain card-blog">
                        <Row>
                          <Col md="8">
                            <CardBody>
                              <h6 className="card-category text-success">
                                Conseils
                              </h6>
                              <CardTitle tag="h3">
                                <a href="/blog/post/admin_management">
                                  Une meilleure gestion des administrateurs de domaines
                                </a>
                              </CardTitle>
                              
                                Les comptes des administrateurs sont souvent 
                                la cible des attaquants car leurs accès priviligiés 
                                permettent généralement d'accéder à tout les 
                                différents systèmes de l'organisation. Nous allons aborder 
                                quelques conseils sur comments les protéger.  {" "}
                                <a href="/blog/post/admin_management" >
                                  Lire la suite
                                </a>
                              
                            </CardBody>
                          </Col>
                          <Col md="4">
                            <div className="card-image">
                             
                                <img
                                  alt="Doamin Admins Managment"
                                  className="img"
                                  src={
                                    require("assets/img/checklist.png")
                                      
                                  }
                                  style={{ maxWidth: "70%"}}
                                />
                            
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
                  
              <hr />
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default BlogPosts;
