import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function Log4j() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <ColorNavbar />
      <BlogPostHeader />
     
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="8">
                    <h1>Vulnérabilité Log4j - Remote Code Execution (RCE)</h1>
                    <Col className="ml-auto mr-auto" md="10">
                        <div className="text-center">
                            <Badge className="main-tag" color="danger">
                            Vulnérabilité
                            </Badge>
                            <h6 className="title-uppercase">10 Décembre 2021</h6>
                        </div>
                    </Col>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <div className="article-content" style={{ fontFamily:"Roboto, sans-serif", fontSize: "16px", color: "#7f7f7f" }}>
                        Les versions Log4j inférieures à la version 2.15.0 sont vulnérables à une exécution de code à distance (RCE: Remote Code Execution).<br/>
                        Cette vulnérabilité publiée le 10.12.2021 est critique et activement exploitée.
                        <br />
                        <br />
                        L'exploit de cette vulnérabilité est disponible sur internet et facile à mettre en pratique.
                        <br />
                    
                        <h3>
                            <strong>CVE-2021-44228</strong>
                        </h3>
                        <br />
                    
                        CVE-2021-44228 est une vulnérabilité émergente dans le paquet de journalisation Java nommé "Log4j", 
                        avec une menace critique de mauvais acteurs obtenant facilement l'exécution de code à distance (RCE) 
                        sur les serveurs utilisants "Log4j". Le danger réside dans le fait que "Log4j" est tellement 
                        omniprésent qu'il est utilisé avec des logiciels Apache comme Apache Struts, Solr, Druid, ainsi que 
                        d'autres technologies comme Redis, ElasticSearch et même des jeux vidéo comme Minecraft. 
                        <br /> <br /> 

                        Différents sites web de fabricants et de fournisseurs se sont avérés être affectés comme Apple, Twitter, 
                        Steam, Tesla et bien d'autres. <br />
                        Sans vouloir donner l'impression que le ciel nous tombe sur la tête, les chercheurs en sécurité 
                        informatique considèrent que cette faille ressemble beaucoup à celle de "Shellshock", en raison de 
                        l'énorme surface d'attaque qu'elle représente. En définitive, des millions d'applications 
                        utilisent Log4j pour la journalisation.
                        <br /> <br /> 

                        L'unique chose qu'un acteur malveillant doit fournir pour réussir son attaque est une simple ligne de texte.
                        < br />

                        <h3>
                            <strong>Exploitation</strong>
                        </h3>
                        <br />
                        Les acteurs de la menace incluront probablement l'exploit, une simple ligne de texte, 
                        dans de simples connexions HTTP (dans l'en-tête User-Agent ou dans des données 
                        des formulaires lors d'une requête POST). 
                        
                        <br /> <br /> 
                        Les organisations constatent déjà des signes d'exploitation et les attaques
                        ne feront que d'augmenter. Il ne s'agit pas d'une attaque ciblée étant donné que les scans de cette 
                        vulnérabilité aient commencés avant même qu'un numéro CVE lui ait été attribué.

                        <h3>
                            <strong>Correctif</strong>
                        </h3>
                        <br />
                        Un correctif pour la vulnérabilité CVE-2021-44228 a été publié, mais malheureusement, de nombreux 
                        utilisateurs devront compter sur leurs fournisseurs afin que les mises à jour de 
                        sécurité soient appliquées sur leurs propres produits.<br /> <br /> 
                        
                        Si votre organisation utilise Apache Log4j, il est vivement recommandé d'effectuer une mise à jour 
                         à la version Log4j-2.1.50-rc2 dès que possible.
                        <br />
                    </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default Log4j;
