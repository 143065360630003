import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import { Link } from 'react-router-dom'

// core components

function AboutUsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/banner_2.jpg") +
            ")",
        }}
      >
        <div className="filter filter-black" />
        <div className="content-center">
          <Container>
            <h1>
              ICTrust <br />
            </h1>
            <h3>Êtes-vous prêt pour un service "out-of-the box"? <br /> </h3>
            <div style={{marginTop: "20px"}}>
            <Link to="/about-us#contact-us">
              <Button
                    className="btn-round"
                    color="danger"
                  >
                   Challengez-nous!
                  </Button>
            </Link>
            </div>
                
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
