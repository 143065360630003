/*!

=========================================================
* Paper Kit PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
import AboutUs from "views/AboutUs.js";
import Services from "views/Services";
import BlogPosts from "views/BlogPosts";
import ServicesDetails from "views/ServicesDetails";
import PostgreSQL from "views/posts/PostgreSQL.js";
import Log4j from "views/posts/Log4j.js";
import DomainAdministratorManagement from "views/posts/DomainAdministratorManagement.js";
import BlogPost from "views/BlogPost";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" exact={true} render={(props) => <Index {...props} />} />
      <Route path="/services" exact={true} render={(props) => <Services {...props} />} /> 
      <Route path="/services/details" exact={true} render={(props) => <ServicesDetails {...props} />} /> 
      <Route path="/about-us" exact={true} render={(props) => <AboutUs {...props} />} />
      <Route path="/blog" exact={true} render={(props) => <BlogPosts {...props} />} />
      <Route path="/blog/post/log4j" component={Log4j} />
      <Route path="/blog/post/postgresql_hardening" component={PostgreSQL} />
      <Route path="/blog/post/admin_management" component={DomainAdministratorManagement} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
