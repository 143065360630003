
import React from "react";

// reactstrap components
import {
    CardBody,
    Container,
    Card,
    CardHeader,
    Collapse,
    Row,
    Col,
} from "reactstrap";

// core components

function SectionSolution() {
    const [collapses, setCollapses] = React.useState([]);
    const changeCollapse = collapse => {
        if (collapses.includes(collapse)) {
            setCollapses(collapses.filter(prop => prop !== collapse));
        } else {
            setCollapses([...collapses, collapse]);
        }
    };
    return (
        <>
            <div className="section cd-section section-gray md-auto mr-auto" id="solutions">
                <Container className="md-auto mr-auto " >
                    <Row>
                        <Col className="text-center">
                            <h2 className="title">Solutions sur-mesure</h2>
                            <h5 className="text-center" md="2">
                            Nous fournissons des systèmes et des solutions qui intègrent l'ensemble 
                            de vos processus. Nous adaptons les solutions existantes en fonction 
                            de vos besoins ou nous développons un nouveau concept spécialement pour vous.
                            </h5>
                        </Col>
                    </Row>
                    <Row >
                        <Col className=" mr-auto " >
                            <div className="testimonials-2 section-testimonials">
                                <Container>
                                    <Row>
                                        <Col className="mr-auto ml-auto">
                                            <div className="testimonials-people">
                                                <img
                                                    alt="..."
                                                    className="left-first-person add-animation"
                                                    src={require("assets/img/src_6.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="left-second-person add-animation"
                                                    src={require("assets/img/src_5.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="left-third-person add-animation"
                                                    src={require("assets/img/src_4.png") }
                                                />
                                                <img
                                                    alt="..."
                                                    className="left-fourth-person add-animation"
                                                    src={require("assets/img/src_3.png") }
                                                />
                                                <img
                                                    alt="..."
                                                    className="left-fifth-person add-animation"
                                                    src={require("assets/img/src_2.png") }
                                                />
                                                <img
                                                    alt="..."
                                                    className="left-sixth-person add-animation nc-minimal-down"
                                                    src={require("assets/img/src_1.png") }
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ml-auto mr-auto " md="8" >
                                            <div className="page-carousel" >
                                                <Col className="ml-auto mr-auto text-center"  >
                                                    <div id="acordeon" >
                                                        <div aria-multiselectable={true} id="accordion" role="tablist" >
                                                            <Card className="no-transition">
                                                                <CardHeader className="card-collapse" id="headingOne" role="tab">
                                                                    <h5 className="mb-0 panel-title">
                                                                        <a
                                                                            aria-expanded={collapses.includes(1)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            id="collapseOne"
                                                                            href="/#"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(1);
                                                                            }}
                                                                        >
                                                                            Définir et concevoir{" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(1)}>
                                                                    <CardBody >
                                                                        <div className="ml-auto mr-auto text-center col-sm-9">
                                                                            Nous analysons vos besoins et définissons différentes 
                                                                            solutions et concepts.
                                                    </div>
                                                                    </CardBody>
                                                                </Collapse>
                                                                <CardHeader className="card-collapse" id="headingTwo" role="tab">
                                                                    <h5 className="mb-0 panel-title">
                                                                        <a
                                                                            aria-controls="collapseTwo"
                                                                            aria-expanded={collapses.includes(2)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            href="/#"
                                                                            id="collapseTwo"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(2);
                                                                            }}
                                                                        >
                                                                            Réaliser et implémenter{" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(2)}>
                                                                    <CardBody>
                                                                    <div className="ml-auto mr-auto text-center col-sm-9">
                                                                        Nous réalisons et implémentons la solution choisie 
                                                                        tout en effectuant les tests nécessaires à son bon fonctionnement 
                                                                        (SAT:  "Site Acceptance Test").
                                                                    </div>
                                                </CardBody>
                                                                </Collapse>
                                                                <CardHeader
                                                                    className="card-collapse"
                                                                    id="headingThree"
                                                                    role="tab"
                                                                >
                                                                    <h5 className="mb-0 panel-title">
                                                                        <a
                                                                            aria-controls="collapseThree"
                                                                            aria-expanded={collapses.includes(3)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            href="/#"
                                                                            id="collapseThree"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(3);
                                                                            }}
                                                                        >
                                                                            Documenter {" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(3)}>
                                                                    <CardBody>
                                                                    <div className="ml-auto mr-auto text-center col-sm-9">
                                                                        Nous fournissons la documentation nécessaire 
                                                                        à l'utilisation et à la maintenance 
                                                                        de la solution implémentée.
                                                                    </div>
                                                                         
                                                </CardBody>
                                                                </Collapse>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col className="ml-auto md-auto">
                                            <div className="testimonials-people">
                                                <img
                                                    alt="..."
                                                    className="right-first-person add-animation"
                                                    src={require("assets/img/src_7.png") }
                                                    style={{objectFit: "contain"}}
                                                    
                                                />
                                                <img
                                                    alt="..."
                                                    className="right-second-person add-animation"
                                                    src={require("assets/img/src_8.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="right-third-person add-animation"
                                                    src={require("assets/img/src_9.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="right-fourth-person add-animation"
                                                    src={require("assets/img/src_10.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="right-fifth-person add-animation"
                                                    src={require("assets/img/src_11.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="..."
                                                    className="right-sixth-person add-animation"
                                                    src={require("assets/img/src_12.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionSolution;
