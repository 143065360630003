
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionAudit() {
  return (
    <>
        <div className="section secion cd-section" id="audit">
            <div className="blog-1" id="blog-1">
                <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                    <h2 className="title">Audit de sécurité et conseils</h2>
                    <br />
                    <Card className="card-plain card-blog">
                        <Row>
                        <Col md="5">
                            <div className="card-image">
                            <img
                                alt="..."
                                className="img"
                                src={
                                require("assets/img/compliance.jpg")                                }
                            />
                            </div>
                        </Col>
                        <Col md="6">
                            <CardBody>
                            <CardTitle tag="h3">
                                <h6 className="card-category text-info">
                                    Normes de conformité
                                </h6>
                            </CardTitle>
                            <p className="card-description">
                                Un audit de conformité évalue vos systèmes et/ou vos applications
                                en fonction des meilleures pratiques en matière de sécurité. 
                            </p>
                            </CardBody>
                        </Col>
                        </Row>
                    </Card>
                    <Card className="card-plain card-blog">
                        <Row>
                        <Col md="6">
                            <CardBody>
                            {/*<h6 className="card-category text-danger">
                                <i className="fa fa-free-code-camp mr-1" />
                                Trending
                            </h6>*/}
                            <CardTitle tag="h3">
                                <h6 className="card-category text-info">
                                   Conseils
                                </h6>
                            </CardTitle>
                            <p className="card-description">
                            {/*Si vous n'avez pas totalement confiance dans votre dispositif 
                            de sécurité de l'information ou dans votre capacité à gérer 
                            les risques informatiques, */}
                            Nous fournissons des services et des conseils qui offrent 
                            une assurance de sécurité continue pour vos systèmes, infrastructures 
                            et applications. 
                            </p>
                            </CardBody>
                        </Col>
                        <Col md="5">
                            <div className="card-image">
                            <img
                                alt="..."
                                className="img"
                                src={
                                require("assets/img/conseil.jpg")

                                }
                            />
                            </div>
                        </Col>
                        </Row>
                    </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        </div>
    </>
  );
}

export default SectionAudit;
