import React from "react";


import { MdWeb } from "@react-icons/all-files/md/MdWeb";
import { FaNetworkWired } from "@react-icons/all-files/fa/FaNetworkWired";
import { BiNetworkChart } from "@react-icons/all-files/bi/BiNetworkChart";
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { GiPoliceTarget } from "@react-icons/all-files/gi/GiPoliceTarget";
import { AiFillSecurityScan } from "@react-icons/all-files/ai/AiFillSecurityScan";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
function SectionPentest() {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <div className="section section-gray cd-section" id="pentest">
          <Container style={{display: "flex"}} >
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h2 className="title">Tests d'intrusion</h2>
                <br />
                Un test d'intrusion, test de pénétration ou pentest est une méthode qui consiste à se mettre dans la peau d'un attaquant afin d'analyser le niveau de sécurité de votre organisation. 

                <br/><br/>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="4">
                    <Card className="card-blog" >
                      <CardBody>
                        <div className="card-image">
                            <div className="icon icon-danger ">
                              <MdWeb size={50}/>
                            </div>
                        </div>
                        
                        <CardTitle>
                        <h6 className="card-category text-info">Pentest Web</h6>
                        </CardTitle>
                        <p className="card-description">
                         Mesurer le niveau de sécurité de votre site ou de vos applications web<br />
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4">
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image">
                        
                          <div className="icon icon-danger ">
                            <FaNetworkWired size={50}/>
                          </div>
                       
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">Pentest Interne</h6>
                        </CardTitle>
                        <p className="card-description">
                         Simuler une attaque depuis le réseau interne de votre entreprise <br />
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4">
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image" >
                       
                          <div className="icon icon-danger ">
                            <BiNetworkChart size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">Pentest Externe</h6>
                        </CardTitle>
                        <p className="card-description" >
                          Évaluer le niveau de défense de votre réseau externe  <br />
                          <br />
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4">
                    <Card className="card-blog">
                      <CardBody>
                      <div className="card-image" >
                       
                          <div className="icon icon-danger ">
                            <FaMobileAlt size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">Pentest Mobile</h6>
                        </CardTitle>
                        <p className="card-description">
                        Mesurer le niveau de sécurité de vos applications mobiles  <br />
                        <br />
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4">
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image" >
                        
                          <div className="icon icon-danger ">
                            <GiPoliceTarget size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">Ingénierie Sociale</h6>
                        </CardTitle>
                        <p className="card-description">
                          Évaluer les vulnérabilités humaines au sein de votre organisation <br />
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4" >
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image" >
                        
                          <div className="icon icon-danger">
                            <AiFillSecurityScan size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">Scan de vulnérabilités</h6>
                        </CardTitle>
                        <p className="card-description">
                          Rechercher les vulnérabilités connues sans tentative d'exploitation  <br />         
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {
            visible &&
            <div className="wrapper">
            
              <div className="section section-white">
                <Container>
                  <Row>
                    <Col className="ml-auto mr-auto text-center title" md="8">
                      <h1>Sécuriser PostgreSQL - Linux</h1>
                      <Col className="ml-auto mr-auto" md="10">
                      <div className="text-center">
                        <Badge className="main-tag" color="info">
                          Technique
                        </Badge>
                        <h6 className="title-uppercase">14 juillet 2021</h6>
                      </div>
                    </Col>
                    </Col>
                  </Row>
                  <Row>
                    
                    <Col className="ml-auto mr-auto" md="8">
                    
                    </Col>
                  </Row>
                </Container>
                </div>
                </div>
          }


        </div>
        {/* ********* END BLOGS 2 ********* */}
    </>
  );
}
export default SectionPentest;
