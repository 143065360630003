import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div className="section section-components section-dark" >
        <Row>
          <Col lg="6" md="12" >
           
              <img
                alt="..."
                className="components-macbook"
                src={
                  require("assets/img/index_1_800x800.jpg")
                    
                }
                style={{ maxHeight: "450px", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}
              />
           
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">Ce que nous offrons</h3>
              <br />
              <h5 className="description">
                  Le coût moyen des cyberattaques est de 3,86 millions de dollars en 2020. 
                  Les entreprises, les instutitions publiques ainsi que les 
                  organisations à but non lucratif font partie des organisations 
                  ciblées chaque jour et ne sont souvent pas préparées 
                  à répondre aux incidents de sécurité.  <br />
                <div style={{marginTop: "10px"}} >
                  C'est là que nous intervenons! <br />
                </div>
                <div style={{marginTop: "10px"}} >
                  Nous vous aidons et accompagnons à la prévention des cyberattaques 
                  et à assurer la sécurité de votre entreprise.
                </div>
                {/*Passionés avec des valeurs 
                fondamentales de notre temps de service, nous utiliserons notre 
                ensemble de compétences pour sécuriser votre environnement.</div>*/}
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
