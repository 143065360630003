import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// Formspree
import { useForm } from '@formspree/react';

// core components

function SectionContactUs() {
  //Formspree return 
  const [state, handleSubmit] = useForm("mrgrealj");
  if (state.succeeded) {
    return (
      <div className="section section-gray section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/webp/challenge.webp") +
              ")",
          }}
        >
          <Container >
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Nous contacter
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div style={{color: "black !important " }} className="description">
                            <h4 className="info-title">
                              Où nous trousver
                            </h4>
                            <p>
                              Avenue Reller 14<br />
                              1800 Vevey
                            </p>
                          </div>
                        </div>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Nous appeler</h4>
                            <p>
                              +41 76 787 10 07<br />
                              Lun - Ven 8h30-18h30
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto ml-auto text-center" md="5">
                        <CardBody>
                          <Row>
                          <div className="description">
                            <h5 className="info-title">Votre message a bien été envoyé</h5>
                          </div>
                          </Row>
                        </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>
          </div>
        )
  }

  return (
    <>
     
        {/* ********* CONTACT US 1 ********* */}
        <div className=" section-gray section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/webp/challenge.webp") +
              ")",
          }}
        >
          <Container >
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Nous contacter
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-email-85" />
                          </div>
                          <div className="description" >
                            <h4 style={{ color: "black  !important"}}>
                              Email
                            </h4>
                            <div style={{ color: "black  !important"}}>
                              info@ictrust.ch<br />
                              <br />
                              Pour des communications chiffrées, veuillez nous envoyer votre 
                              clé PGP.
                            </div>
                          </div>
                        </div>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description" style={{ color: "black  !important"}}>
                            <h4 className="info-title" >Nous appeler</h4>
                            <p>
                              +41 76 787 10 07<br />
                              Lun - Ven 8h30-18h30
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    
                    <Col className="mr-auto" md="5">
                      <Form id="contact-form" method="post" role="form" onSubmit={handleSubmit}>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  Prénom
                                </label>
                                <Input
                                  name="name"
                                  placeholder="Prénom"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  Nom
                                </label>
                                <Input
                                  name="name"
                                  placeholder="Nom"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Email
                            </label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                              errors={state.errors}
                            />
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Message
                            </label>
                            <Input
                              id="message"
                              name="message"
                              placeholder="Votre message"
                              type="textarea"
                              rows="6"
                              errors={state.errors}
                            />
                          </FormGroup>
                          <Row>
                            {/*<Col md="6">
                              <FormGroup check>
                                <div class="g-recaptcha" data-sitekey="6Lc91zQbAAAAANVSSGtDKZ9AOaant7er_T2eltK9" data-size="compact" data-theme="dark" style={{ transform: "scale(0.77)", transformOrigin: "0 0" }}></div>
                              </FormGroup>
        </Col>*/}
                            <Col md="6">
                              <Button 
                                className="pull-right"
                                color="primary"
                                type="submit"
                               
                                disabled={state.submitting}
                              >
                                Envoyer
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
    </>
  );
}

export default SectionContactUs;
