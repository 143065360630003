import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
// sections for this page
import SectionServices from "./sections-sections/SectionServices.js";
import SectionComponents from "./presentation-sections/SectionComponents.js";
import SectionWhyUs from "./presentation-sections/SectionWhyUs.js";
import SectionContactUs from "./sections-sections/SectionContactUs.js";
import ProductPage from "./examples/ProductPage.js";
//import SectionPackagePentest from "./sections-packages/SectionPackagePentest.js";


function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <LandingPageHeader />
      <SectionComponents />
      <SectionServices />
      <SectionWhyUs />
      {/* <SectionPackagePentest /> */}
      <SectionContactUs />
      <FooterBlack />
    </>
  );
}

export default Index;
