import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionWhyUs() {
  return (
    <>
      
      <div className="section section-components section-gray">
        <Row>
        <Col sm={{ size: 'auto', offset: 1 }}  lg="4" md="4">
            <Container className="basic-container">
              <h3 className="title">Bénéficiez de notre expertise et améliorez votre projet numérique </h3>
              <br />
              <h5 className="description">
              Nous travaillons comme de véritables partenaires en fournissant non seulement un rapport de test de sécurité mais aussi en vous soutenant tout au long du processus. 
              Nous sommes désireux de partager nos connaissances.
              </h5>
            </Container>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <div className="image-container">
              <img
                alt="..."
                className="components-macbook"
                src={
                  require("assets/img/webp/expertise.webp")
                    
                }
                style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}
              />
            </div>
          </Col>
        </Row>
      </div>

    </>
  );
}

export default SectionWhyUs;
