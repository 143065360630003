import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionContactUs from "./sections-sections/SectionContactUs";

function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Container>
            
            <h3 className="title-uppercase">À propos</h3>
            <p>
            ICTrust est une entreprise spécialisée dans la sécurité de 
            l'information et la protection des données, pour leurs défis 
            techniques et leurs enjeux de société.
            L'entreprise a été créée dans le but de rendre la sécurité
             de l'information et la protection des données accessibles 
             et adaptées aux nouvelles technologies. 
            </p>
            
            {/*<p>
              Passionés par la cybersécurité, nous mettons tout   
              en oeuvre pour vos différents projets. 
              Nous construisons une image de succès de vos produits numériques à travers 
              des perspectives et des sessions techniques et nous nous focalisons 
              sur leur sécurité.
            </p>*/}
            <div className="team-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Certification</h2>
                
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <Card className="card-blog card-plain">
                  <div className="card-avatar" >
                    
                      <img
                        alt="OSCE-Certification"
                        src={require("assets/img/CTP-OSCE-badge.png") }
                        style={{ width: "80%", maxWidth: "100px", height: "auto" }}
                      />
                    
                  </div>
                  <CardBody>
                    
                      <div className="author">
                        <CardTitle tag="h4">Offensive Security Certified Expert</CardTitle>
                      </div>
                    
                    <p className="text-center">
                      Les OSCEs ont des compétences de niveau expert en matière de 
                      tests de pénétration. Ils ont prouvé qu'ils pouvaient 
                      élaborer leurs propres exploits, exécuter des attaques 
                      pour compromettre des systèmes et élever les privilèges.<br/>
                      L'examen intense de 48 heures démontre également que les certifiés 
                      OSCE ont un degré de persistance, de détermination et de capacité à 
                      travailler sous pression supérieur à la moyenne et qu'ils peuvent 
                      sortir des sentiers battus pour trouver des moyens innovants 
                      de pénétrer les réseaux internes. 
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
           
            <h2 className="text-center more-info">Nos valeurs</h2>
            <Row >
            <Col md="4" sm="6" style={{ justifyContent: "center", flexDirection: "row"}}>
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="blue"
                    data-radius="none"
                  >
                    <CardBody >
                      <CardTitle tag="h4">
                          Confiance
                      </CardTitle>
                      
                      <p className="card-description">
                      Nous mettons tout en oeuvre afin de 
                      créer et d'entretenir une relation de confiance durable.
                      </p>
                     
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6" style={{justifyContent: "center", flexDirection: "row"}}>
                <div className="card-big-shadow" >
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="green"
                    data-radius="none"
                  >
                    <CardBody>
                      <CardTitle tag="h4">
                          Respect
                      </CardTitle>
                      <div style={{textAlign: "center"}}>
                      <p className="card-description " style={{  color: 'white' }}>
                      Nous nous engageons à développer des produits novateurs
                      et des services qui protègent l'information et respectent 
                      la vie privée.
                      </p>
                      </div>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6" style={{ justifyContent: "center", flexDirection: "row"}}>
                <div className="card-big-shadow" >
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="orange"
                    data-radius="none"
                  >
                    <CardBody >
                      <CardTitle tag="h4">
                        Qualité
                      </CardTitle>
                      <div style={{textAlign: "center"}}>
                        <p className="card-description" >
                        Passioné par notre métier, nous accordons de 
                        l'importance au sens de nos actions quotidiennes.
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
            </Row>
            {/*<h2 className="title-uppercase text-center">
              "We
              <i className="fa fa-heart heart mr-3 ml-1" style={{ marginLeft: "30px"}}/>
               what we do"
          </h2>*/}
          </Container>
        </div>
      </div>
      <SectionContactUs />
      <FooterBlack />
    </>
  );
}

export default AboutUs;
