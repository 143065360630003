import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

import { Link } from 'react-router-dom'

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function DomainAdministratorManagement() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <ColorNavbar />
      <BlogPostHeader />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h1>Une meilleure gestion des administrateurs de domaines</h1>
                  <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                    <Badge className="main-tag" color="danger">
                      Conseils
                    </Badge>
                    <h6 className="title-uppercase">6 juillet 2021</h6>
                  </div>
                  </Col>
                </Col>
              </Row>
              <Row>
                
                <Col className="ml-auto mr-auto" md="8">
                
                  <div className="article-content" style={{ fontFamily:"Roboto, sans-serif", fontSize: "16px", color: "#7f7f7f" }}>
                    <br />
                    Les comptes des administrateurs sont souvent la cible des attaquants 
                    car leurs accès priviligiés permettent généralement d'accéder aux
                    différents systèmes de l'organisation. <br />
                    La plupart des entreprises gèrent leur groupe d'administrateurs 
                    comme elles le feraient pour n'importe quel autre groupe  
                    au sein de leur système informatique. <br />
                    Nous allons voir ci-dessous quelques bonnes pratiques pour mieux
                    se protéger.

                    <h4>
                        <b>Restreindre les privilèges</b>
                    </h4>
                    <br />
                    En limitant le nombre d'administrateurs, vous réduisez 
                    non seulement votre surface d'attaque, mais vous facilitez 
                    également la gestion grâce à un périmètre plus restreint. <br/>
                    Le groupe des administrateurs ne devrait compter 
                    que des utilisateurs qui en ont vraiment besoin pour exercer 
                    leurs fonctions. <br />
                    Les comptes de service, qui sont également une 
                    cible des attaquants (attaque telle que 
                    le <a href="https://attack.mitre.org/techniques/T1558/003/">Kerberoasting</a>), 
                    bénéficient souvent des droits administrateur alors qu'ils ne sont pas 
                    toujours nécessaires pour leur bon fonctionnement.
                  


                    <h4>
                        <b>Faire la part des choses</b>
                    </h4>
                    <br />
                    Les administrateurs ne doivent pas utiliser le compte administrateur pour effectuer
                    des tâches non administratives. <br /> 
                    Il est recommandé d'avoir deux types de comptes: un 
                    compte avec un minimum de privilèges et un deuxième pour les tâches administrateur.
                    Cela signifie que tous les utilisateurs doivent se connecter avec un compte qui dispose 
                    des autorisations minimales pour effectuer leur travail.<br />
                    Il est judicieux de ne pas rendre les comptes administrateurs 
                    facilement reconnaissables par des normes de dénomination telles 
                    que l'ajout de "Admin" ou "Adm" avant ou après le nom du compte.

                    <h4>
                        <b>Renforcer la politique des mots de passe</b>
                    </h4>
                    <br />
                    Les comptes administrateurs doivent avoir une politique plus stricte
                    des exigences en matière de mot de passe par rapport aux comptes ordinaires.<br />
                    Il est recommandé d'utiliser une authentification à deux facteurs, 
                    des cartes à puce ou des jetons matériels lorsque cela est possible et 
                    spécialement pour les accès à distance. <br />
                    Les normes telles que le CIS (Center for Internet Security) ou la publication NIST 800-63 spécifient 
                    des recommandations concernant les mots de passe.<br />
                    Il est préférable d'avoir un mot de passe de plus de 20 caractères 
                    pour les administrateurs de domaine au lieu du minimum recommandé.<br />

                      
                    <h4>
                        <b>Désactiver, puis purger les comptes des anciens administrateurs</b>
                    </h4>
                    <br />
                    Les comptes administrateurs des anciens employés doivent être au moins désactivés.
                    En effet, l'employeur peut encore avoir besoin des accès afin de suivre 
                    les communications envoyées dans la boîte de réception de l'ex-employé pendant une 
                    période déterminée. Il est recommandé de supprimer le compte s'il n'est plus utile.
                    
                    <h4>
                        <b>Configurer des alertes avec des actions automatiques</b>
                    </h4>
                    <br />
                    La mise en oeuvre et la configuration d'un programme de 
                    gestion des comptes à privilèges (PAM) peuvent être assez 
                    longues et coûteuses, mais c'est un investissement qui en vaut 
                    la peine.<br />
                    Il est recommandé lors de la configuration de commencer par 
                    les comptes des administrateurs qui sont les plus critiques. <br />
                    Assurez-vous de faire respecter les exigences en matière 
                    de vérification et de complexité des mots de passe après chaque 
                    changement.
                    <br /><br />
                    <div className="content-center text-center">
                      <Link to="/about-us#contact-us">
                        <Button
                            className="btn"
                            color="danger"
                          >
                          Nous contacter
                        </Button>
                      </Link>
                    </div>
            
                  </div>
                  <br />
                  <hr />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default DomainAdministratorManagement;
